<template>
  <div class="mailEditorBlock">
    <v-textarea hide-details class="hover" auto-grow single-line flat solo :placeholder="$t('EnterText')"> </v-textarea>
  </div>
</template>

<script>
export default {
  name: "blockText"
}
</script>

<style scoped>
.mailEditorBlock {
  cursor : pointer;
}
.mailEditorBlock:hover {
  background: #00000022;
}
</style>