<template>
  <div>
    <h3 class="py-6"> {{ $t(element.type)    }}</h3>
    <v-row>
      <v-col cols="6">
        <v-select v-if="element.type === 'heading' " outlined dense hide details v-model="element.size" :items="sizesSelect" :label="$t('FontSize')"></v-select>

        <v-text-field v-if="element.type === 'text' "
                      v-model="element.size"
                      @keypress="NUMBER_INPUT($event,element.size,2)"
                      outlined dense hide-details clearable
                      :label="$t('FontSize')" />

      </v-col>
      <v-col cols="6">
        <v-select outlined dense hide details v-model="element.weight" :items="weightSelect" :label="$t('FontWeight')"></v-select>
      </v-col>
    </v-row>

    <v-row class="mt-n3">
      <v-col cols="6">
        <v-select outlined dense hide details v-model="element.align" :items="alignSelect" :label="$t('Align')"></v-select>
      </v-col>
      <v-col cols="6">
        <ws-color-picker v-model="element.color" :label="$t('Color')"></ws-color-picker>
      </v-col>
    </v-row>

    <h5 class="mb-3">{{$t('PaddingSettings')}}</h5>
    <v-row>
      <v-col cols="6" class="">
        <v-text-field v-model="element.paddingTop"
                      @keypress="NUMBER_INPUT($event,element.paddingTop,3)"
                      outlined dense hide-details clearable
                      :label="$t('PaddingTop')" />
      </v-col>
      <v-col cols="6"  >
        <v-text-field v-model="element.paddingBottom"
                      @keypress="NUMBER_INPUT($event,element.paddingBottom,3)"
                      outlined dense hide-details clearable
                      :label="$t('PaddingBottom')" />
      </v-col>
      <v-col cols="6" class="">
        <v-text-field v-model="element.paddingLeft"
                      @keypress="NUMBER_INPUT($event,element.paddingLeft,3)"
                      outlined dense hide-details clearable
                      :label="$t('PaddingLeft')" />
      </v-col>
      <v-col cols="6"  >
        <v-text-field v-model="element.paddingRight"
                      @keypress="NUMBER_INPUT($event,element.paddingRight,3)"
                      outlined dense hide-details clearable
                      :label="$t('PaddingRight')" />
      </v-col>
    </v-row>

    <v-btn  elevation="0" @click="$emit('delete')" :color="wsWARNING" class="noCaps mt-6" dark>
      <v-icon>mdi-delete</v-icon>
      {{ $t('Delete') }}
    </v-btn>




  </div>
</template>

<script>
export default {
  name: "editElementRow",
  props : {
    value : {
      type : Object,
      default() { return {} }
    }
  },
  data() {
    return {
      element : {
        elements : []
      }
    }
  },
  watch : {
    value :  {
      handler(val) {
        if(JSON.stringify(val) !== JSON.stringify(this.element) ) {
          this.element = this.value
        }
      },
      deep : true
    }
  },
  computed : {
    sizesSelect() {
      return [
        { text : 'H1' , value : 'h1' },
        { text : 'H2' , value : 'h2' },
        { text : 'H3' , value : 'h3' },
        { text : 'H4' , value : 'h4' },
        { text : 'H5' , value : 'h5' },
        { text : 'H6' , value : 'h6' },

      ]
    },
    weightSelect() {
      return [
        { text : this.$t('FontWeightLight') , value : 'light' },
        { text : this.$t('FontWeightMedium') , value : 'medium' },
        { text : this.$t('FontWeightBold') , value : 'bold' }
      ]
    },
    alignSelect() {
      return [
        { text : this.$t('AlignLeft')   , value : 'left' },
        { text : this.$t('AlignCenter') , value : 'center' },
        { text : this.$t('AlignRight')  , value : 'right' },
      ]
    }
  },
  mounted() {
    if ( this.value.type ) {
      this.element = this.value
    }
  }
}
</script>

<style scoped>

</style>