<template>
  <v-dialog persistent v-model="$store.state.newsletter.displayEditor" fullscreen width="100%"  class="fill-height pa-6">

    <v-sheet class="fill-height">
      <v-row  no-gutters  style="flex-wrap: nowrap;" class="fill-height" >
        <!-- Constructor Email Body -->
        <!-- Constructor Editor Params -->
        <v-col  :style="`min-width: ${sideMenu ? '280px' : '28px'}; transition : all 0.3s ease; width: ${sideMenu ? '280px' : '28px'}; max-width: ${sideMenu ? '280px' : '28px'};`" class="flex-grow-0 flex-shrink-0 sideTransition">

          <v-sheet height="100vh" :color="wsBACKGROUND"  >

            <v-row class="no-gutters flex-wrap flex-column fill-height " >

              <!-- Business/Fine Logo-->
              <v-col cols="auto" class="shrink"  >
                <v-sheet color="transparent" height="60"  class="d-flex   align-center" :class="sideMenu ? 'justify-space-between' : 'justify-center'" >
                  <div style="width: 100%" v-if="sideMenu" >

                    <h3 class="pl-6" :style="`color : ${wsACCENT}`">
                      <v-icon :color="wsACCENT">mdi-mail</v-icon>
                      {{ $t('Newsletter') }}
                    </h3>
                  </div>

                  <div v-if="sideMenu">
                    <v-btn :color="wsACCENT"
                           :class="[{'mr-5' : sideMenu}]"
                           @click="sideMenu = !sideMenu"  icon  >
                      <v-icon  >mdi-arrow-collapse-{{ sideMenu ? 'left' : 'right' }}</v-icon>
                    </v-btn>
                  </div>


                </v-sheet>
              </v-col>

              <v-col cols="auto" class="grow overflow-y-auto " style="height: 100px"  >
                <v-sheet color="transparent" height="40" v-ripple style="cursor: pointer" class="d-flex align-center" >
                  <v-icon :color="wsACCENT" class="ml-6 mr-4">mdi-chevron-left</v-icon>
                  <h5 v-if="sideMenu || SM"  :style="`color : ${wsACCENT}`">{{  $t('Back')  }}</h5>
                </v-sheet>

                <ws-avalon-navigation-list
                    v-if="!$store.state.dashboard.displayPageNavigation"
                    v-model="navigation"
                    :items="navigationItems"
                    mandatory
                />

              </v-col>

            </v-row>

          </v-sheet>

        </v-col>
        <v-col  style="min-width: 100px; max-width: 100%;" class="flex-grow-1 pl-3 wsRounded flex-shrink-0 ">

          <v-sheet no-padding>

            <template #pre_header.wide>
              <v-sheet color="transparent" width="100%" class="px-3 py-1 d-flex justify-space-between">
                <h2 class="font-weight-light">{{ name }}</h2>
                <v-btn  @click="saveContent"   class="noCaps" outlined > {{ $t('Save') }}</v-btn>
              </v-sheet>

            </template>
            <div class="d-flex align-center">
              <v-btn @click="$store.state.newsletter.displayEditor = false" icon text class="mr-2" ><v-icon large>mdi-chevron-left</v-icon></v-btn>
              <h2 class="font-weight-light">{{ name }}</h2>
            </div>
            <div>

            </div>

            <v-sheet :color="newsLetterParams.backgroundColor" class="d-flex justify-center">
              <v-sheet width="700" min-width="700" @click="unselect" :color="wsACCENT" class="">
                <v-sheet class=" wsRounded " :color="wsBACKGROUND">

                  <v-sheet ref="printMe"  id="content" class=" " :color="newsLetterParams.backgroundColor" style="position: relative;">

                    <draggable v-model="emailBody"
                               :empty-insert-threshold="30"
                               :forceFallback="true"
                               handle=".handle"
                               tag="div">

                      <v-sheet :color="wsBACKGROUND" @click.stop=""  v-for="(row,i) in emailBody" :key="i" class="d-flex align-center ">

                        <v-sheet :color="!row.background ? newsLetterParams.backgroundColor :  row.background "
                                 :style="row.uuid === selectedBlock.uuid ? `outline : 2px solid ${wsDARKLIGHT}; outline-offset: -2px` : '' "
                                 style="width: 100%;  " class="fill-height">
                          <blockRow  @selectBlock="selectBlock(row)" :admin="admin"  @selectElement="selectElement($event,row)" v-if="row.type === 'block_row'" :value="row"  />
                          <block-text v-if="row.type === 'block_text'"></block-text>
                          <block-image-text v-if="row.type === 'block_image_text'"></block-image-text>
                        </v-sheet>

                        <v-sheet class="handle fill-height" color="transparent"  >
                          <v-icon  style="cursor: grab" large  >mdi-drag</v-icon>
                        </v-sheet>
                      </v-sheet>

                    </draggable>

                    <v-sheet :color="wsBACKGROUND" class="pr-9 ">

                      <v-sheet :color="newsLetterParams.backgroundColor"
                               :class="emailBody.length === 0 ?  'pa-6' : '' "
                               class="d-flex justify-space-between" >
                        <v-btn @click="addBlock('block_row')"
                               :x-large="emailBody.length === 0"
                               outlined
                               :color="wsACCENT"
                               class="noCaps " elevation="0"  block >
                          <v-icon>mdi-plus</v-icon>
                          {{ $t('AddBlock') }}
                        </v-btn>
                      </v-sheet>
                    </v-sheet>

                    <v-sheet :color="wsBACKGROUND" class="pr-9 ">
                      <v-sheet :color="newsLetterParams.backgroundColor" class="pt-3">
                        <h5 class="font-weight-medium text-center">  {{ $store.state.business.selectedBusiness.name }} </h5>
                        <h5 class="font-weight-light text-center mt-3"> {{ $t('Phone') }}: {{newsLetterParams.phone ? newsLetterParams.phone : $t('EnterPhone') }}</h5>
                        <h5 class="font-weight-light text-center "> {{ $t('Email') }}: {{newsLetterParams.email ? newsLetterParams.email : $t('EnterEmail')}}</h5>
                        <h5 class="font-weight-light text-center"> {{ $t('Address') }}: {{newsLetterParams.address  ? newsLetterParams.address : $t('EnterAddress')}}</h5>
                        <h6 class="font-weight-light text-center mt-3"> {{ $t('EmailSubscriptionText') }}  <span class="font-weight-medium">  {{ $store.state.business.selectedBusiness.name }}</span></h6>
                        <h6 class="font-weight-light text-center "> {{ $t('EmailUnsubscribeText') }}</h6>
                        <h5 class="font-weight-light text-center mt-3">{{ (new Date()).getFullYear() }} - {{ $t('AllRightsReserved') }}</h5>
                      </v-sheet>
                    </v-sheet>


                    <v-sheet :color="wsBACKGROUND" class="pr-9 fill-height">
                      <v-sheet class="fill-height" :color="newsLetterParams.backgroundColor">
                      </v-sheet>
                    </v-sheet>

                  </v-sheet>

                </v-sheet>
              </v-sheet>
            </v-sheet>

          </v-sheet>


        </v-col>
        <!-- Constructor Editor Params -->
        <v-col  style="min-width: 350px; width: 350px; max-width: 350px;" class="flex-grow-0 flex-shrink-0 sideTransition">
          <v-sheet color="#ffffff" elevation="2"  class="fill-height overflow-y-auto ">




            <ws-navigation-header
                v-model="navigation"
                :items="navigationItems"
                @input="changeNavigation"
                no-number
            >

            </ws-navigation-header>


            <div class="d-flex align-center flex-wrap py-3">
              <v-btn @click="selectedElement = {}; selectedBlock={} " v-if="selectedBlock.type" class="noCaps px-0" small text>
                <h4>{{ $t('GeneralEmail') }}</h4> <v-icon small>mdi-chevron-right</v-icon>
              </v-btn>
              <h5 v-else class="font-weight-light">{{ $t('GeneralEmail') }}</h5>
              <v-btn @click="selectedElement = {}" v-if="selectedBlock.type && selectedElement.type" class="noCaps px-0" small text>
                <h4>{{ $t('Block') }}</h4> <v-icon small>mdi-chevron-right</v-icon>
              </v-btn>
              <h5 v-else-if="selectedBlock.type" class="font-weight-light">{{ $t('Block') }}</h5>
              <h5 v-if="selectedElement.type" class="font-weight-light">{{ $t('Element') }}</h5>
            </div>
            <v-divider />

            <v-sheet color="red" v-if="!selectedBlock.type">
              <h5 class="py-3">{{ $t('Background') }}</h5>
              <wsColorPicker v-model="newsLetterParams.backgroundColor" />
              <h5 class="py-3">{{ $t('ContactInfo') }}</h5>
              <ws-phone-input v-model="newsLetterParams.phone" :label="$t('Phone')"> </ws-phone-input>
              <v-text-field v-model="newsLetterParams.email" outlined dense hide-details :label="$t('Email')"> </v-text-field>
              <v-textarea height="65" class="mt-6" v-model="newsLetterParams.address" outlined dense hide-details :label="$t('Address')"> </v-textarea>
            </v-sheet>

            <editBlockRow        v-if="selectedBlock.type === 'block_row' && !selectedElement.type"
                                 v-model="selectedBlock"
                                 @delete="displayConfirmDeleteBlock = true"
                                 @duplicate="duplicateBlock"
            />
            <editElementHeading  v-if="['text','heading'].includes(selectedElement.type)"
                                 v-model="selectedElement"
                                 @delete="displayConfirmDeleteElement = true"
            />
            <editElementButton   v-if="selectedElement.type === 'button' "
                                 v-model="selectedElement"
                                 @delete="displayConfirmDeleteElement = true"
            />
            <editElementImage    v-if="selectedElement.type === 'image' "
                                 v-model="selectedElement"
                                 @delete="displayConfirmDeleteElement = true"/>

          </v-sheet>
        </v-col>
      </v-row>
    </v-sheet>






    <wsDialog v-model="displayConfirmDeleteElement"
              :title="$t('ConfirmDeleteElement')"
              display-confirm-delete
              @delete="deleteElement" >
    </wsDialog>
    <wsDialog v-model="displayConfirmDeleteBlock"
              :title="$t('ConfirmDeleteBlock')"
              display-confirm-delete
              @delete="deleteBlock" >
    </wsDialog>


  </v-dialog>
</template>

<script>
import wsAvalonNavigationList from "@/components/AvalonEditor/UI/sidebar/UI/wsAvalonNavigationList";
import blockRow from "@/components/pages/businessDashboard/businessNewsletter/email/emailConstructor/editor/editorBlock";
import blockText from "@/components/pages/businessDashboard/businessNewsletter/email/emailConstructor/UI/blockText";
import blockImageText from "@/components/pages/businessDashboard/businessNewsletter/email/emailConstructor/UI/blockImageText";
import wsColorPicker from "@/components/UI/wsColorPicker";
import editBlockRow from "@/components/pages/businessDashboard/businessNewsletter/email/emailConstructor/params/editBlockRow";
import editElementHeading
  from "@/components/pages/businessDashboard/businessNewsletter/email/emailConstructor/params/editElementHeading";
import editElementButton from "@/components/pages/businessDashboard/businessNewsletter/email/emailConstructor/params/editElementButton";
import editElementImage from "@/components/pages/businessDashboard/businessNewsletter/email/emailConstructor/params/editElementImage";
import draggable from "vuedraggable";
import {mapActions} from "vuex";
export default {
  name: "newsletterDesign",
  props : {
    uuid : {
      type : String,
      default : ''
    },
    name : {
      type : String,
      default : ''
    },
    admin : {
      type : Boolean,
      default : false
    }
  },
  components : {
    // Editor,
    wsAvalonNavigationList,
    draggable,
    blockText,
    blockImageText,
    blockRow,
    wsColorPicker,
    editBlockRow,
    editElementHeading,
    editElementButton,
    editElementImage
  },
  data() {
    return {
      sideMenu : true,
      navigation : 'email',
      emailBody : [],
      content : '',
      selectedBlock : {},
      selectedElement : {},
      newsLetterParams : {
        backgroundColor : '#ffffff'
      },
      selectedElementColumn : 0,
      selectedElementIndex : 0,

      displayConfirmDeleteElement : false,
      displayConfirmDeleteBlock   : false
    }
  },
  computed : {
    navigationItems() {
      let items = [ {  text : this.$t('Mail'  ) , value : 'email'  } ]

      if ( this.navigation === 'block') {
        items.push( {  text : this.$t('Block'  ) , value : 'block' })
      }
      if ( this.navigation === 'element') {
        items.push(  {  text : this.$t('Block')   , value : 'block'   } )
        items.push(  {  text : this.$t('Element') , value : 'element' } )
      }

      return items
    },
    defaultBlock() {
      return {
        row : {
          type : 'block_row',
          name : this.$t('BockRow'),
          backgroundColor : this.newsLetterParams.backgroundColor,
          rows : 1,
          elements : [[]],
          uuid : ""
        }
      }
    },
    editorOptions() {
      return {
        minHeight : 500,
        width : '100%',
        plugins: 'powerpaste',
        menubar : false,
        toolbar: true,
        inline: true,
        force_br_newlines : true,
        forced_root_block : false,
        statusbar: false,
        placeholder: this.$t('EnterText'),
        powerpaste_word_import: 'clean',
        powerpaste_html_import: 'clean',
        // skin: 'naked',
        setup: this.editorSetup,
        browser_spellcheck: true,
        // content_style: 'div { font  }'
        // skins : naked document outside
      }
    },
  },
  methods : {
    ...mapActions('newsletter', [
      'SAVE_NEWSLETTER_DESIGN',
      'GET_NEWSLETTER_DESIGN',
      'SAVE_ADMIN_NEWSLETTER_DESIGN',
      'GET_ADMIN_NEWSLETTER_DESIGN'
    ]),

    changeNavigation(item) {
      switch(item) {
        case 'email' :
          this.selectedElement = {};
          this.selectedBlock={}
              break;
        case 'block' :
          this.selectedElement = {}
            break;
      }
      this.notify(item)
    },

    unselect() {
      this.selectedBlock = {}
      this.selectedElement = {}
    },
    selectBlock(row) {
      this.navigation = 'block'
      this.selectedBlock = row
      this.selectedElement = {}
    },
    selectElement(data,row) {
      this.navigation = 'element'
      this.selectedElement = data.element;
      this.selectedElementColumn = data.column
      this.selectedElementIndex = data.index
      this.selectedBlock = row;
    },
    addBlock(type) {
      switch(type) {
        case 'block_row' :
          var block = JSON.parse(JSON.stringify(this.defaultBlock.row));
          block.uuid = this.$uuid.v4()
          this.emailBody.push(block)
            this.selectedBlock = this.emailBody[this.emailBody.length - 1]
          break;
      }
    },
    deleteElement() {

        this.selectedElement = {}
        this.selectedBlock.elements[this.selectedElementColumn].splice(this.selectedElementIndex,1)
        this.displayConfirmDeleteElement = false

    },
    deleteBlock() {
      let index = this.emailBody.findIndex(el => el.uuid === this.selectedBlock.uuid)
      if ( index !== -1 ) {
        this.selectedBlock = {}
        this.emailBody.splice(index,1)
        this.displayConfirmDeleteBlock = false
      }
    },
    duplicateBlock() {
      let index = this.emailBody.findIndex(el => el.uuid === this.selectedBlock.uuid)
      if ( index !== -1 ) {
        let block = JSON.parse(JSON.stringify(this.selectedBlock))
        block.uuid = this.$uuid.v4()
        this.emailBody.splice(index,0,block)
        this.displayConfirmDeleteBlock = false
      }
    },
    async snapshot() {

      const el = this.$refs.printMe.$el;
      // add option type to get the image version
      // if not provided the promise will return
      // the canvas.
      const options = {
        type: 'dataURL',
        useCORS : true
      }
      console.log(el)
      el.style.fontFeatureSettings = '"liga" 0';
      let output = await this.$html2canvas(el, options);

      var arr = output.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

      while(n--){
        u8arr[n] = bstr.charCodeAt(n);
      }

      let blob =  new Blob([u8arr],  {type:mime});

      var a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = "file.png";
      document.body.appendChild(a);
      a.click();
      a.remove();


    },
    async saveContent() {

      let data = {
        newsletter_id : this.uuid,
        params : this.newsLetterParams,
        body : this.emailBody
      }

      let result = !this.admin ? await this.SAVE_NEWSLETTER_DESIGN(data) : await this.SAVE_ADMIN_NEWSLETTER_DESIGN(data)

      if ( !result ) {
        return
      }
      this.notify(this.$t('ChangesSaved'))
      this.$emit('save')

    }
  },
  async mounted() {
    let result = !this.admin ?  await this.GET_NEWSLETTER_DESIGN(this.uuid) : await this.GET_ADMIN_NEWSLETTER_DESIGN(this.uuid)
    if ( !result || result === true ) { return }
    this.emailBody = result.body || []
    this.newsLetterParams = result.params || {}
  }
}
</script>

<style scoped>
.singleLineEditor {
  padding: 25px;
}
.singleLineEditor *[contentEditable="true"]:focus,
.singleLineEditor *[contentEditable="true"]:hover {
  outline: none;
}
</style>