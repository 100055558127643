<template>
  <dash-page :title="entity.name"
             :subtitle="$t('Newsletter')"
             :header-route="localeLink('dashobard/newsletter')"
              no-side
             v-model="selectedRoute"
             side-navigation-no-link >

    <template #default>

        <!-- :: QUICK STATS PANEL :: -->
        <v-sheet dark class="wsRoundedHalf mt-6 py-3 px-6"
                 :color="wsDARK" style="background: linear-gradient(25deg, rgba(47, 84, 152, 0.76), rgba(157, 60, 0, 0.57))">
          <v-row class="pa-0 ma-0">
            <v-col cols="4" xs="4" sm="4" md="2" lg="2" xl="2" class=" pa-0 ma-0 align-center ">
              <h6 class="text-center">{{ $t('TotalReceivers') }}</h6>
              <h3 class="text-center">{{ entity.receivers_count|| 0 }}</h3>
            </v-col>
            <v-col cols="4" xs="4" sm="4" md="2" lg="2" xl="2" class=" pa-0 ma-0 align-center ">
              <h6 class="text-center">{{ $t('OpenedLetters') }}</h6>
              <h3 class="text-center">{{ entity.opened_count|| 0 }}</h3>
            </v-col>
            <v-col cols="4" xs="4" sm="4" md="2" lg="2" xl="2" class=" pa-0 ma-0 align-center ">
              <h6 class="text-center">{{ $t('FailedLetters') }}</h6>
              <h3 class="text-center">{{ entity.failed_count || 0 }}</h3>
            </v-col>
            <v-col cols="4" xs="4" sm="4" md="2" lg="2" xl="2" class=" pa-0 ma-0 align-center ">
              <h6 class="text-center">{{ $t('OpenPercent') }}</h6>
              <h3 class="text-center">{{ entity.opened_percent|| 0 }}%</h3>
            </v-col>
            <v-col cols="4" xs="4" sm="4" md="2" lg="2" xl="2" class=" pa-0 ma-0 align-center ">
              <h6 class="text-center">{{ $t('LinkClicks') }}</h6>
              <h3 class="text-center">{{ entity.link_clicks || 0 }}</h3>
            </v-col>
            <v-col cols="4" xs="4" sm="4" md="2" lg="2" xl="2" class=" pa-0 ma-0 align-center ">
              <div class="d-flex align-center fill-height">
                <v-chip outlined>
                  <h3 class="text-center">  {{ entity.is_published ? $t('Published') : $t('Draft')  }}</h3>
                </v-chip>
              </div>

            </v-col>

          </v-row>
        </v-sheet>

        <v-row v-if="entity.is_published && ready" class="mt-3">

          <!-- Receivers-->
          <v-col style="transition: all 0.5s ease"  cols="12" xs="12" sm="12" md="12" :lg="!displayReceivers ? 6 : 12" :xl="!displayReceivers ? 6 : 12"  >
            <v-sheet class="wsRoundedHalf pa-6 "
                     style="transition: all 0.5s ease"
                     :max-height="!displayReceivers ? 300 : 600"
                     :height="!displayReceivers ? 300 : 600"
                     :min-height="!displayReceivers ? 300 : 600"
                     :color="entity.receivers.length > 0 ? 'white' : wsACCENT"
                     :dark="entity.receivers.length === 0">

              <!-- Receivers View-->
              <div v-if="entity.receivers.length > 0">
                <!-- Receivers Short-->
                <div class="d-flex justify-space-between align-center">
                  <h4 class="">{{$t('Receivers')}}:</h4>
                  <v-btn @click="openReceiversDetails" dark rounded small :color="wsACCENT" class="noCaps">
                    <v-icon v-if="displayReceivers" small>mdi-chevron-left</v-icon>
                    {{ !displayReceivers ?  $t('Details') : $t('Back')}}
                    <v-icon v-if="!displayReceivers" small>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
                <v-simple-table v-if="!displayReceivers" class="mt-6" dense>
                  <tbody>
                  <tr v-for="(receiver,i ) in entity.receivers" :key="i">
                    <td class="px-0"><h5 class="font-weight-light">{{i +1}}</h5></td>
                    <td><h5> {{ receiver.user_name }}</h5></td>
                    <td><h5 class="font-weight-light"> {{ receiver.email }}</h5></td>
                  </tr>
                  </tbody>
                </v-simple-table>
                <!-- Receivers Extended -->
                <v-data-table class="mt-6" v-if="displayReceivers" dense :items="receivers" :headers="headers" :disable-sort="SM" :hide-default-header="SM">
                  <template v-slot:item="{item}">
                    <tr>
                      <td align="left"><h5>{{ item.user_name }}</h5></td>
                      <td ><h5 class="font-weight-light">{{ item.email }}</h5></td>
                      <td class="text-no-wrap"><wsChip icon="mdi-calendar" :text="PARSE_TIME_STAMP(item.open_date)"></wsChip></td>
                      <td class="text-no-wrap"><wsChip icon="mdi-email-open-outline" :text="item.open_count"></wsChip></td>
                      <td class="text-no-wrap"><wsChip icon="mdi-link" :text="item.links_count"></wsChip></td>
                    </tr>
                  </template>
                </v-data-table>
              </div>

              <!-- No Receivers-->
              <div v-else class="fill-height d-flex align-center justify-center">
                <div>
                  <div class="d-flex justify-center">
                    <v-icon size="82">mdi-account-multiple</v-icon>
                  </div>
                  <h5 class="font-weight-light text-center mt-3">{{ $t('NoReceiversYet') }}</h5>
                </div>
              </div>



            </v-sheet>
          </v-col>
          <!-- Links -->
          <v-col style="transition: all 1s ease" v-if="!displayReceivers"  cols="12" xs="12" sm="12" md="12" lg="6" xl="6"  >
            <v-sheet class="wsRoundedHalf pa-6 "
                     :color="entity.links.length > 0 ? 'white' : wsACCENT"
                     :dark="entity.links.length === 0"
                     max-height="300"
                     height="300"
                     min-height="300" >

              <!-- Links Screen -->
              <div v-if="entity.links.length > 0">
                <div class="d-flex justify-space-between align-center">
                  <h4 class="">{{$t('LinkClicks')}}:</h4>
                  <v-btn dark rounded small :color="wsACCENT" class="noCaps">
                    {{ $t('Details')}}
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
                <v-simple-table class="mt-6" dense>
                  <tbody>
                  <tr v-for="(link,i ) in entity.links" :key="i">
                    <td class="px-0"><h5 class="font-weight-light">{{i +1}}</h5></td>
                    <td><h5 class="font-weight-light">{{ link.link }} </h5></td>
                    <td><h5>{{ link.count }}</h5></td>
                  </tr>
                  </tbody>
                </v-simple-table>
              </div>
              <!-- No Links -->
              <div v-else class="fill-height d-flex align-center justify-center">
                <div>
                  <div class="d-flex justify-center">
                    <v-icon size="82">mdi-link</v-icon>
                  </div>
                  <h5 class="font-weight-light text-center mt-3">{{ $t('NoOpenedLinksYet') }}</h5>
                </div>
              </div>


            </v-sheet>
          </v-col>

          <!-- Template screen will be here -->

        </v-row>

      <v-sheet v-else-if="ready" class="wsRoundedHalf pa-6 mt-6">
        <newsletterAdminOverview @publish="initPage" @editTemplate="$store.state.newsletter.displayEditor = true" :entity="entity" />
      </v-sheet>

    </template>

    <template #dialog>
      <newsletterDesign admin  @save="entity.template_is_set = true" v-if="$store.state.newsletter.displayEditor" :uuid="uuid" :name="entity.name"   />
    </template>


  </dash-page>
</template>

<script>
import {mapActions} from "vuex";
import newsletterDesign    from "@/components/pages/businessDashboard/businessNewsletter/newsletter/newsletterDesign";
import newsletterAdminOverview from "@/components/pages/adminDashboard/newsletter/newsletter/newsletterAdminOverview";
export default {
  name: "viewAdminNewsletter",
  components : {
    newsletterDesign,
    newsletterAdminOverview,
    // newsletterReceivers,
  },
  props : {
    uuid : {
      type : String,
      default : ''
    }
  },
  data() {
    return {
      ready : false,
      selectedRoute : 'overview',
      entity : {
        links : [],
        receivers : [],
      },
      receivers : [],
      displayReceivers : false
    }
  },
  computed : {
    headers() {
      return [
          { text : this.$t('Name')  , value : 'user_name' },
          { text : this.$t('Email') , value : 'email'     },
          { text : this.$t('Date') , value : 'open_date'     },
          { text : this.$t('OpenTimes') , value : 'open_date'     },
          { text : this.$t('LinksClick') , value : 'open_date'     },
      ]
    },
    menu() {
      return [
        { text : this.$t('Design')     ,  value : 'design'    , icon : 'mdi-cog' },
        { text : this.$t('Receivers')  ,  value : 'receivers' , icon : 'mdi-cog' },
        { text : this.$t('Overview')   ,  value : 'overview'  , icon : 'mdi-cog' },
      ]
    }
  },
  methods : {
    ...mapActions('newsletter', [
      'GET_ADMIN_NEWSLETTER',
      'EDIT_ADMIN_NEWSLETTER',
      'GET_ADMIN_NEWSLETTER_RECEIVERS_STATS'
    ]),

    async openReceiversDetails() {
      let result = await this.GET_ADMIN_NEWSLETTER_RECEIVERS_STATS(this.uuid)
      if ( !result || result === true) { return }
      this.receivers = result
      this.displayReceivers = !this.displayReceivers

    },

    async initPage() {
      let result = await this.GET_ADMIN_NEWSLETTER(this.uuid)
      if ( !result || result === true) { return }
      this.entity = result
      this.displayDialog = false
      this.ready = true
    }

  },

  mounted() {
    this.initPage()
  }

}
</script>

<style scoped>

</style>