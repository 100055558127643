<template>
  <div class="mailEditorBlock">
   <div>
     <v-row class="ma-0 pa-0">
       <v-col class="ma-0 pa-0">
         <wsImageUpload />
       </v-col>
       <v-col class="ma-0 pa-0" >
         <v-textarea hide-details class="hover" auto-grow single-line flat solo :placeholder="$t('EnterText')"> </v-textarea>
       </v-col>
     </v-row>

   </div>
  </div>
</template>

<script>
import wsImageUpload from "@/components/UI/wsImageUpload";
export default {
  name: "blockImageText",
  components : {
    wsImageUpload
  }
}
</script>

<style scoped>
.mailEditorBlock {
  cursor : pointer;
}
.hover:hover {
  background: #00000022;
}
.mailEditorBlock:hover {
  background: #00000022;
}
</style>