<template>
  <v-menu
      ref="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      bottom
      transition="scale-transition"
      offset-y
      v-model="displayMenu"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          v-model="color"
          dense
          :label="label"
          :placeholder="placeholder"
          readonly
          clearable
          outlined
          hide-details
          v-bind="attrs"
          v-on="on"
      >
        <template slot="prepend-inner">
          <v-icon :color="color">mdi-circle</v-icon>
        </template>
      </v-text-field>
    </template>

    <v-sheet class="pa-3">
      <div class="d-flex align-center my-3">
        <v-text-field class="mr-3"
                      :value="color"
                      @change="changeColor"
                      dense
                      :label="label"
                      :placeholder="placeholder"
                      prepend-inner-icon="mdi-palette"
                      hide-details
                      clearable
                      outlined
        >
          <template slot="prepend-inner">
            <v-icon :color="color">mdi-palette</v-icon>
          </template>
        </v-text-field>
        <v-btn :color="wsACCENT" class="noCaps" dark @click="displayMenu = false"> {{  $t('Choose')  }}</v-btn>
      </div>
      <v-color-picker v-model="color"
                      hide-inputs hide-mode-switch width="600" show-swatches   swatches-max-height="240"></v-color-picker>
    </v-sheet>

  </v-menu>
</template>

<script>
export default {
  name: "wscolorPicker",
  props : ['value','label','placeholder'],
  data() {
    return {
      color : '',
      displayMenu : false,
    }
  },
  watch : {
    color(value) {
      if ( !value ) { this.color = '#00000000' }
      this.$emit('input',this.color)
    },
    value(value) {
      if ( !value ) { value = '#00000000' }
      this.color = value
    }
  },
  methods : {
    changeColor(color) {
      this.color = color
    }
  },
  mounted() {
    if  (this.value ) {
      this.color = this.value
    }
  }
}
</script>

<style scoped>

</style>