<template>
  <v-sheet  class="fill-height d-flex align-center justify-center wsRoundedHalf pa-12 px-16">

    <v-sheet color="transparent"  class=" pa-12 px-16">

      <div class="d-flex  align-center">
          <v-btn class="mr-3" icon x-large outlined :color="!entity.receivers_type ? grey : wsACCENT"><h1>1</h1></v-btn>
        <v-btn x-large @click="openEditReceivers" text class="noCaps">
          <div class="d-flex align-center">
            <h1 v-if="entity.receivers_type !== 'course'" class="font-weight-light">{{ !entity.receivers_type ? $t('SetNewsletterReceiversType') : $t('receivers_' + entity.receivers_type)  }}</h1>
            <h1 v-else class="font-weight-light">{{ getCourseName  }}</h1>
            <v-icon  x-large>mdi-chevron-right</v-icon>
          </div>
        </v-btn>
      </div>

      <div class="d-flex  align-center mt-6">
          <v-btn class="mr-3" icon x-large outlined :color="!entity.template_is_set ? grey : wsACCENT"><h1>2</h1></v-btn>
        <v-btn x-large @click="$emit('editTemplate')" text class="noCaps">
          <div class="d-flex align-center">
            <h1 class="font-weight-light">{{ !entity.template_is_set ? $t('CreateLetterTemplate') : $t('EditLetterTemplate')  }}</h1>
            <v-icon  x-large>mdi-chevron-right</v-icon>
          </div>
        </v-btn>
      </div>

      <v-btn @click="displayPublish = true"
             :disabled="!entity.template_is_set || !entity.receivers_type"
             class="mt-16 noCaps"
             :outlined="!entity.template_is_set || !entity.receivers_type"
             :dark="entity.template_is_set === true && entity.receivers_type !== null"
             :color="wsATTENTION"
             block x-large  >
        <h1 class="font-weight-light">
          {{ $t('Publish') }}
        </h1>
      </v-btn>

    </v-sheet>


    <wsDialog v-model="displayReceivers"
              @save="setReceivers"
              :title="$t('SelectReceivers')">

      <h5 class="mb-3">{{ $t('ChooseReceiversType') }} </h5>
      <v-select class="mb-3" v-model="receiversData.receivers_type" dense outlined hide-details :items="getReceiversSelect()" />

      <v-expand-transition>
        <div v-if="receiversData.receivers_type === 'course' ">
          <h5 class="mb-3">{{ $t('ChooseCourse') }} </h5>
          <v-autocomplete v-model="receiversData.receivers_entity_id" dense outlined hide-details :items="coursesSelect" />
        </div>
      </v-expand-transition>

    </wsDialog>

    <wsDialog v-model="displayPublish"
              @save="publishNewsletter"
              :save-text="$t('Confirm')"
              :title="$t('ConfirmNewsletterPublish')">

      <h5 class="mb-3 text-center font-weight-light">{{ $t('ConfirmNewsletterPublishMessage') }} </h5>

    </wsDialog>



  </v-sheet>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "newsletterOverview",
  props : {
    entity : {
      type : Object,
      receivers_entity_id : null,
      default() { return { type : 'all' } }
    }
  },
  data() {
    return {
      displayReceivers : false,
      displayPublish : false,
      receiversData : {
        type : 'all'
      },
      coursesSelect : []
    }
  },
  computed  :{
    getCourseName() {

      if (this.entity.receivers_entity_id && this.coursesSelect.length > 0) {
        let course = this.coursesSelect.find(el => el.value === this.entity.receivers_entity_id )
        if  (course) { return course.text }
      }

      return this.$t('ChooseCourse')
    }
  },
  methods : {
    ...mapActions('newsletter', [
        'SET_ADMIN_NEWSLETTER_RECEIVERS_TYPE',
        'PUBLISH_ADMIN_NEWSLETTER',
        'GET_COURSES_SELECT',
    ]),

    async openEditReceivers() {
      this.receiversData = {
        uuid : this.entity.uuid,
        receivers_type : this.entity.receivers_type,
        receivers_entity_id : this.entity.receivers_entity_id
      }
      this.displayReceivers = true
    },
    async setReceivers() {
      this.receiversData.uuid = this.entity.uuid
      let result = await this.SET_ADMIN_NEWSLETTER_RECEIVERS_TYPE(this.receiversData)
      if ( !result ) { return }

      this.entity.receivers_type = this.receiversData.receivers_type
      this.entity.receivers_entity_id = this.receiversData.receivers_entity_id
      this.displayReceivers = false
    },

    async publishNewsletter() {

      let result = await this.PUBLISH_ADMIN_NEWSLETTER(this.entity.uuid)
      if ( !result ) {
        this.notify(this.$t(this.$store.state.ajax.error))
        return
      }

      this.$emit('publish')

      this.notify(this.$t('NewsletterPublished'))
      this.displayPublish = false

    },

    getReceiversSelect() {
      return [
        { text : this.$t('receivers_business_owners')  , value : 'admin_business_owners'  },
        { text : this.$t('receivers_managers')  , value : 'admin_managers'  },
        // { text : this.$t('receivers_all_admin_users')  , value : 'admin_all_admin_users'  },
        // { text : this.$t('receivers_all_system_users')  , value : 'admin_all_system_users'  },
      ]
    }
  },
  async mounted() {
    this.coursesSelect = await this.GET_COURSES_SELECT()
  }
}
</script>

<style scoped>

</style>