<template>
  <div>
    <div class="d-flex justify-space-between align-center">
      <h3 class="pt-6 pb-6"> {{  block.name  }}</h3>
      <v-btn @click="$emit('duplicate')" class="noCaps" outlined>
        <v-icon class="mr-1">mdi-content-copy</v-icon>
        {{ $t('Duplicate') }}
      </v-btn>
    </div>


    <v-select v-model="block.rows"
              :items="[1,2,3]"
              outlined dense hide-details
              :label="$t('Columns')" />

    <h5 class="my-3">{{ $t('Background') }}</h5>
    <v-row>

      <v-col>
        <v-text-field v-model="block.borderRadius"
                      @keypress="NUMBER_INPUT($event,block.borderRadius,3)"
                      outlined dense hide-details clearable
                      :label="$t('BorderRadius')" />
      </v-col>
      <v-col>
        <wsColorPicker v-model="block.backgroundColor" />
      </v-col>
    </v-row>

    <h5 class="mb-3 mt-n6">{{$t('PaddingSettings')}}</h5>
    <v-row>
      <v-col cols="6" class="">
        <v-text-field v-model="block.paddingTop"
                      @keypress="NUMBER_INPUT($event,block.paddingTop,3)"
                      outlined dense hide-details clearable
                      :label="$t('PaddingTop')" />
      </v-col>
      <v-col cols="6"  >
        <v-text-field v-model="block.paddingBottom"
                      @keypress="NUMBER_INPUT($event,block.paddingBottom,3)"
                      outlined dense hide-details clearable
                      :label="$t('PaddingBottom')" />
      </v-col>
      <v-col cols="6" class="">
        <v-text-field v-model="block.paddingLeft"
                      @keypress="NUMBER_INPUT($event,block.paddingLeft,3)"
                      outlined dense hide-details clearable
                      :label="$t('PaddingLeft')" />
      </v-col>
      <v-col cols="6"  >
        <v-text-field v-model="block.paddingRight"
                      @keypress="NUMBER_INPUT($event,block.paddingRight,3)"
                      outlined dense hide-details clearable
                      :label="$t('PaddingRight')" />
      </v-col>
    </v-row>
    <h5 class="mb-3 mt-3">{{$t('MarginSettings')}}</h5>
    <v-row>
      <v-col cols="6" class="">
        <v-text-field v-model="block.marginTop"
                      @keypress="NUMBER_INPUT($event,block.marginTop,3)"
                      outlined dense hide-details clearable
                      :label="$t('PaddingTop')" />
      </v-col>
      <v-col cols="6"  >
        <v-text-field v-model="block.marginBottom"
                      @keypress="NUMBER_INPUT($event,block.marginBottom,3)"
                      outlined dense hide-details clearable
                      :label="$t('PaddingBottom')" />
      </v-col>
      <v-col cols="6" class="">
        <v-text-field v-model="block.marginLeft"
                      @keypress="NUMBER_INPUT($event,block.marginLeft,3)"
                      outlined dense hide-details clearable
                      :label="$t('PaddingLeft')" />
      </v-col>
      <v-col cols="6"  >
        <v-text-field v-model="block.marginRight"
                      @keypress="NUMBER_INPUT($event,block.marginRight,3)"
                      outlined dense hide-details clearable
                      :label="$t('PaddingRight')" />
      </v-col>
    </v-row>

    <v-btn elevation="0"  @click="$emit('delete')" :color="wsWARNING" class="noCaps mt-6" dark>
      <v-icon>mdi-delete</v-icon>
      {{ $t('Delete') }}
    </v-btn>

  </div>
</template>

<script>
export default {
  name: "editBlockRow",
  props : {
    value : {
      type : Object,
      default() { return {} }
    }
  },
  data() {
    return {
      block : {
        elements : []
      }
    }
  },
  watch : {
    value :  {
      handler(val) {
        if(JSON.stringify(val) !== JSON.stringify(this.block) ) {
          this.block = this.value
        }
      },
      deep : true
    }
  },
  mounted() {
    if ( this.value.type ) {
      this.block = this.value
    }
  }
}
</script>

<style scoped>

</style>